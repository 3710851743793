import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import layout1 from "../layouts/layout/layout1.vue";

import dashboard from "../pages/dashboard.vue";
// import allUsers from "../pages/general/users/all_users.vue";
// import add_new_user from "../pages/general/users/add_new_user.vue";

import order_list from "../pages/application/orders/order_list.vue";
import order_detail from "../pages/application/orders/order_detail.vue";
import order_tracking from "../pages/application/orders/order_tracking.vue";
import vendor_list from "../pages/application/vendors/vendor_list.vue";
import create_vendor from "../pages/application/vendors/create_vendor.vue";
import translation from "../pages/application/localization/translation.vue";
import currency_rate from "../pages/application/localization/currency_rate.vue";
import taxes from "../pages/application/localization/taxes.vue";
import products from "../pages/application/product/products.vue";
import add_product from "../pages/application/product/add_new_product.vue";
import menu_lists from "../pages/application/menus/menu_lists.vue";
import create_menu from "../pages/application/menus/create_menu.vue";
import coupon_list from "../pages/application/coupons/coupon_list.vue";
import create_update_coupon from "../pages/application/coupons/create_update_coupon.vue";
import product_review from "../pages/application/product_review.vue";
import invoice from "../pages/application/invoice.vue";
import support_ticket from "../pages/application/support_ticket.vue";
import profile_setting from "../pages/application/settings/profile_setting.vue";
import reports from "../pages/application/reports.vue";
import list_page from "../pages/application/list_page.vue";
import log_in from "../pages/application/log_in.vue";
// import forgot_password from "../pages/application/forgot_password.vue";
// import register from "../pages/application/register.vue";
import create_update_post from "../pages/application/posts/create_update_post.vue";
import post_list from "../pages/application/posts/post_list.vue";
import create_update_post_category from "../pages/application/post_categories/create_update_post_category.vue";
import post_category_list from "../pages/application/post_categories/post_category_list.vue";
import create_update_label from "../pages/application/labels/create_update_label.vue";
import label_list from "../pages/application/labels/label_list.vue";
import create_update_brand from "../pages/application/brands/create_update_brand.vue";
import create_update_popup from "../pages/application/popup/create_update_popup.vue";
import brand_list from "../pages/application/brands/brand_list.vue";
import popup_list from "../pages/application/popup/popup_list.vue";
import create_update_product_category from "../pages/application/product_categories/create_update_product_category.vue";
import product_category_list from "../pages/application/product_categories/product_category_list.vue";
import create_update_product from "../pages/application/products/create_update_product.vue";
import product_list from "../pages/application/products/product_list.vue";
import create_update_discount from "../pages/application/discounts/create_update_discount.vue";
import discount_list from "../pages/application/discounts/discount_list.vue";
import stock_list from "../pages/application/stock/stock_list.vue";
import delivery_price_list from "../pages/application/delivery_prices/delivery_price_list.vue";
import create_update_delivery_price from "../pages/application/delivery_prices/create_update_delivery_price.vue";
import banner_list from "../pages/application/banners/banner_list.vue";
import create_update_banner from "../pages/application/banners/create_update_banner.vue";
import home_page_list from "../pages/application/home_page/home_page_list.vue";
import create_update_home_page from "../pages/application/home_page/create_update_home_page.vue";


const routes = [
  {
    path: "/",
    name: "dashboard",
    component: dashboard,
    meta: { layout: layout1, breadcrumb: { type: 0 } },
  },
//   {
//     path: "/all_users",
//     name: "allUsers",
//     component: allUsers,
//     meta: {
//       layout: layout1,
//       breadcrumb: { type: 1, title: "All Users", buttonPath: "/add_new_user" },
//     },
//   },
//   {
//     path: "/add_new_user",
//     name: "add_new_user",
//     component: add_new_user,
//     meta: {
//       layout: layout1,
//       breadcrumb: { type: 2, title: "Add New User" },
//     },
//   },
  {
    path: "/order_list",
    name: "order_list",
    component: order_list,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Order List" } },
  },
//   {
//     path: "/order_detail",
//     name: "order_detail",
//     component: order_detail,
//     meta: {
//       layout: layout1,
//       breadcrumb: {
//         type: 3,
//         title: "Order #36648",
//         lis: ["October 21, 2021 at 9:08 pm", "6 items", "Total $5,882.00"],
//       },
//     },
//   },
  {
    path: "/order_tracking",
    name: "order_tracking",
    component: order_tracking,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Order Tracking" } },
  },
  {
    path: "/vendor_list",
    name: "vendor_list",
    component: vendor_list,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Vendor List" } },
  },
  {
    path: "/create_vendor",
    name: "create_vendor",
    component: create_vendor,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Create Vendor" } },
  },
  {
    path: "/translation",
    name: "translation",
    component: translation,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Translation" } },
  },
  {
    path: "/currency_rate",
    name: "currency_rate",
    component: currency_rate,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Currency Rates" } },
  },
  {
    path: "/taxes",
    name: "taxes",
    component: taxes,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Taxes" } },
  },
  {
    path: "/products",
    name: "products",
    component: products,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Products" } },
  },
  {
    path: "/add_product",
    name: "add_product",
    component: add_product,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Add Product" } },
  },
  {
    path: "/menu_lists",
    name: "menu_lists",
    component: menu_lists,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Menu Lists" } },
  },
  {
    path: "/create_menu",
    name: "create_menu",
    component: create_menu,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Create Menu" } },
  },
  {
    path: "/product_review",
    name: "product_review",
    component: product_review,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Product Review" } },
  },
  {
    path: "/invoice",
    name: "invoice",
    component: invoice,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Invoice" } },
  },
  {
    path: "/support_ticket",
    name: "support_ticket",
    component: support_ticket,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Support Ticket" } },
  },
  {
    path: "/profile_setting",
    name: "profile_setting",
    component: profile_setting,
    meta: {
      layout: layout1,
      breadcrumb: { type: 2, title: "Profile Setting" },
    },
  },
  {
    path: "/reports",
    name: "reports",
    component: reports,
    meta: {
      layout: layout1,
      breadcrumb: { type: 2, title: "Report" },
    },
  },
  {
    path: "/list_page",
    name: "list_page",
    component: list_page,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "List Page" } },
  },
  {
    path: "/log_in",
    name: "log_in",
    component: log_in,
  },
//   {
//     path: "/forgot_password",
//     name: "forgot_password",
//     component: forgot_password,
//   },
//   {
//     path: "/register",
//     name: "register",
//     component: register,
//   },
  {
    path: "/create_post_category",
    name: "create_post_category",
    component: create_update_post_category,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Create Post Category" } },
  },
  {
    path: "/edit_post_category/:id/edit",
    name: "edit_post_category",
    component: create_update_post_category,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Edit Post Category" } },
},
  {
    path: "/post_category_list",
    name: "post_category_list",
    component: post_category_list,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Post Category List" } },
  },
  {
    path: "/post_category_trash",
    name: "post_category_trash",
    component: post_category_list,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Post Category Trash" } },
  },
  {
    path: "/create_label",
    name: "create_label",
    component: create_update_label,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Create Label" } },
  },
  {
    path: "/edit_label/:id/edit",
    name: "edit_label",
    component: create_update_label,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Edit Label" } },
},
  {
    path: "/label_list",
    name: "label_list",
    component: label_list,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Label List" } },
  },
  {
    path: "/label_trash",
    name: "label_trash",
    component: label_list,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Label Trash" } },
  },
  {
    path: "/create_post",
    name: "create_post",
    component: create_update_post,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Create Post" } },
  },
  {
    path: "/edit_post/:id/edit",
    name: "edit_post",
    component: create_update_post,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Edit Post" } },
},
  {
    path: "/post_list",
    name: "post_list",
    component: post_list,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Post List" } },
  },
  {
    path: "/post_trash",
    name: "post_trash",
    component: post_list,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Post Trash" } }
  },
  {
    path: "/brand_list",
    name: "brand_list",
    component: brand_list,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Manufacturer List" } },
  },
  {
    path: "/brand_trash",
    name: "brand_trash",
    component: brand_list,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Manufacturer Trash" } },
  },
    {
    path: "/create_brand",
    name: "create_brand",
    component: create_update_brand,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Create Manufacturer" } },
  },
  {
    path: "/edit_brand/:id/edit",
    name: "edit_brand",
    component: create_update_brand,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Edit Manufacturer" } },
},
  {
    path: "/popup_list",
    name: "popup_list",
    component: popup_list,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Popup List" } },
  },
  {
    path: "/popups-trash",
    name: "popups-trash",
    component: popup_list,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Popup Trash" } },
  },
  {
    path: "/create_popup",
    name: "create_popup",
    component: create_update_popup,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Create Popup" } },
  },
  {
    path: "/edit_popup/:id/edit",
    name: "edit_popup",
    component: create_update_popup,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Edit Popup" } },
},
  {
    path: "/create_product_category",
    name: "create_product_category",
    component: create_update_product_category,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Create Product Category" } },
  },
  {
    path: "/edit_product_category/:id/edit",
    name: "edit_product_category",
    component: create_update_product_category,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Edit Product Category" } },
},
  {
    path: "/product_category_list",
    name: "product_category_list",
    component: product_category_list,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Product Category List" } },
  },
  {
    path: "/product_category_trash",
    name: "product_category_trash",
    component: product_category_list,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Product Category Trash" } },
  },
  {
    path: "/create_product",
    name: "create_product",
    component: create_update_product,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Create Product" } },
  },
  {
    path: "/edit_product/:id/edit",
    name: "edit_product",
    component: create_update_product,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Edit Product" } },
},
  {
    path: "/product_list",
    name: "product_list",
    component: product_list,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Product List" } },
  },
  {
    path: "/product_trash",
    name: "product_trash",
    component: product_list,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Product Trash" } },
  },
  {
    path: "/stock_list",
    name: "stock_list",
    component: stock_list,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Stock List" } },
  },
  {
    path: "/create_discount",
    name: "create_discount",
    component: create_update_discount,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Create Discount" } },
  },
  {
    path: "/edit_discount/:id/edit",
    name: "edit_discount",
    component: create_update_discount,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Edit Discount" } },
},
  {
    path: "/discount_list",
    name: "discount_list",
    component: discount_list,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Discount List" } },
  },
  {
    path: "/create_coupon",
    name: "create_coupon",
    component: create_update_coupon,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Create Coupon" } },
},
{
    path: "/edit_coupon/:id/edit",
    name: "edit_coupon",
    component: create_update_coupon,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Edit Coupon" } },
},
{
    path: "/coupon_list",
    name: "coupon_list",
    component: coupon_list,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Coupon List" } },
},
{
    path: "/coupon_trash",
    name: "coupon_trash",
    component: coupon_list,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Coupon Trash" } },
},
  {
    path: "/create_delivery_price",
    name: "create_delivery_price",
    component: create_update_delivery_price,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Create Delivery Price" } },
},
{
    path: "/edit_delivery_price/:id/edit",
    name: "edit_delivery_price",
    component: create_update_delivery_price,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Edit Delivery Price" } },
},
{
    path: "/delivery_price_list",
    name: "delivery_price_list",
    component: delivery_price_list,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Delivery Price List" } },
},
{
    path: "/delivery_price_trash",
    name: "delivery_price_trash",
    component: delivery_price_list,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Delivery Price Trash" } },
},
{
    path: "/order_list",
    name: "order_list",
    component: order_list,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Order List" } },
},
{
    path: "/order_detail/:id",
    name: "order_detail",
    component: order_detail,
    meta: { layout: layout1, breadcrumb: { type: 2, title: "Order Detail" } },
},
{
  path: "/banner_list",
  name: "banner_list",
  component: banner_list,
  meta: { layout: layout1, breadcrumb: { type: 2, title: "Banner List" } },
},
{
  path: "/banners-trash",
  name: "banners-trash",
  component: banner_list,
  meta: { layout: layout1, breadcrumb: { type: 2, title: "Banner Trash" } },
},
{
  path: "/create_banner",
  name: "create_banner",
  component: create_update_banner,
  meta: { layout: layout1, breadcrumb: { type: 2, title: "Create Banner" } },
},
{
  path: "/edit_banner/:id/edit",
  name: "edit_banner",
  component: create_update_banner,
  meta: { layout: layout1, breadcrumb: { type: 2, title: "Edit Banner" } },
},
{
  path: "/home_page_list",
  name: "home_page_list",
  component: home_page_list,
  meta: { layout: layout1, breadcrumb: { type: 2, title: "Home Page List" } },
},
{
  path: "/home-pages-trash",
  name: "home-pages-trash",
  component: home_page_list,
  meta: { layout: layout1, breadcrumb: { type: 2, title: "Home Page Trash" } },
},
{
  path: "/create_home_page",
  name: "create_banner",
  component: create_update_home_page,
  meta: { layout: layout1, breadcrumb: { type: 2, title: "Create Home Page" } },
},
{
  path: "/edit_home_page/:id/edit",
  name: "edit_home_page",
  component: create_update_home_page,
  meta: { layout: layout1, breadcrumb: { type: 2, title: "Edit Home Page" } },
},
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

let isAuthChecked = false;

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  if (!isAuthChecked) {
    try {
      await authStore.getUser();
      isAuthChecked = true;
    } catch (error) {
      console.error(error);
    }
  }
  if (to.name !== 'log_in' && !authStore.user) {
    next({ name: 'log_in' });
  } else if (to.name === 'log_in' && authStore.user) {
    next({ name: 'dashboard' });
  } else {
    next();
  }
});
export default router;
