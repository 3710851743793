import { defineStore } from "pinia";

export let useHomePageStore = defineStore('homePage', {
    state: () => ({
        homePages: [],
        home_pages_trashed: [],
        total_pages:0
    }),

    actions: {
        
        async fill(page = 1, paginated = 1, search = null) {
            try {
                let link = paginated == 1 ? `/home-pages?page=${page}` : `/home-pages?paginated=0`;
                if(search !== null){ link += `&search=${search}`;}
                let paginatedResponse = (search == null || search.length < 2) && paginated == 1;
                const response = await axios.get(link);
                this.homePages = paginatedResponse ? response.data.data : response.data; 
                console.log(this.homePages);
                               
                this.total_pages = paginatedResponse ? response.data.last_page : 1;
            }
            catch (error) {
                console.log(error)
            }
        },
        async get(id) {
            try {
                return await axios
                .get("/home-pages/" + id).then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async getTrashed(page = 1, paginated = 1, search = null) {
            try {
                let link = paginated == 1 ? `/home-pages-trash?page=${page}` : `/home-pages-trash?paginated=0`;
                console.log('link',link);
                
                if(search !== null){ link += `&search=${search}`;}
                let paginatedResponse = (search == null || search.length < 3) && paginated == 1;
                const response = await axios.get(link);

                this.home_pages_trashed = paginatedResponse ? response.data.data : response.data.data;
                console.log('homepagetrashed',this.home_pages_trashed); //ez miert lesz ures tomb?
                
                this.total_pages = paginatedResponse ? response.data.last_page : 1;
            }
            catch (error) {
                console.log('error',error);
                
                console.log(error)
            }
        },
        async create(homePage) {            
            try {
                return await axios
                .post("/home-pages", homePage).then(function (response) {
                    
                    return response;
                });
            }
            catch(error){
                return error.response;
            }
        },
        async update(id, homePage) {
            console.log('id',id);
            console.log('homepage',homePage);
            
            
            try {
                return await axios
                .put("/home-pages/" + id, homePage).then(function (response) {
                    console.log('response',response);
                    
                    return response;
                });
            }
            catch(error){
                console.log('error',error);
                
                return error.response;
            }
        },
        async delete(id) {
            try {
                return axios
                .delete(
                    "/home-pages/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async deleteForever(id) {
            try {
                return axios
                .delete(
                    "/home-pages-delete-forever/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        },
        async restore(id) {
            try {
                return axios
                .post(
                    "/home-pages-restore/" + id
                )
                .then(function (response) {
                    return response;
                });
            }
            catch(error){
                console.log(error);
            }
        }
    }
});


